import React from 'react'

import { Outlet, Navigate } from 'react-router-dom'

import CircleLoading from './CircleLoading'
import { useProfile } from '../services/profileService'

export default function PermissionGuard({ roles }) {
  const { data: me, isLoading } = useProfile()

  if (isLoading) {
    return <CircleLoading />
  }

  if (!roles.includes(me.role)) {
    return <Navigate to="/not-enough-permission" />
  }

  return <Outlet />
}
