import { useMemo } from 'react'

import { DownOutlined } from '@ant-design/icons'
import { Layout, Menu, Dropdown, Button, Spin, Typography, Image, Space } from 'antd'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import LogoImg from '../../assets/imgs/logo-white.png'
import CircleLoading from '../../components/CircleLoading'
import GuardedContent from '../../components/GuardedContent'
import { IMG_FALLBACK, ROLE } from '../../config/constants'
import { useEvent } from '../../services/eventService'
import { useProfile } from '../../services/profileService'
import { logout } from '../../store/Auth'
import { getPriorRoles } from '../../utils/getPriorRoles'

const { Header } = Layout

const PortalHeader = () => {
  const dispatch = useDispatch()
  const { data: profile, isLoading } = useProfile()
  const { eventId } = useParams()
  const { data: event } = useEvent(eventId)

  const userDropdown = useMemo(
    () => ({
      items: [
        {
          key: 1,
          label: <Link to="/account">Profile</Link>,
        },
        {
          key: 2,
          label: <Link to="/home">Events</Link>,
        },
        {
          key: 3,
          label: <Menu.Divider />,
        },
        {
          key: 4,
          label: (
            <GuardedContent roles={getPriorRoles(ROLE.Manager)}>
              <Link to="/organization">My Company</Link>
            </GuardedContent>
          ),
        },
        {
          key: 5,
          label: (
            <GuardedContent roles={getPriorRoles(ROLE.Admin)}>
              <Link to="/organizations">Manage Companies (Admin)</Link>
            </GuardedContent>
          ),
        },
        {
          key: 6,
          label: (
            <GuardedContent roles={getPriorRoles(ROLE.Admin)}>
              <Link to="/events">Manage Events (Admin)</Link>
            </GuardedContent>
          ),
        },
        { key: 7, label: <Menu.Divider /> },
        {
          key: 8,
          label: <Typography.Text type="danger">Log out</Typography.Text>,
          onClick: () => dispatch(logout()),
        },
      ],
    }),
    [dispatch],
  )

  return (
    <Header className="portal-header">
      <Link to="/home">
        <Space align="center">
          <img src={LogoImg} className="brand-logo" alt="Hybridley" />
          {process.env.REACT_APP_ENV === 'development' && <h1>DEV</h1>}
        </Space>
      </Link>
      {event && (
        <Space>
          <Image
            src={event.theme.assets.logo || 'error'}
            width={240}
            height={60}
            fallback={IMG_FALLBACK}
            preview={false}
            style={{ objectFit: 'contain' }}
          />
          <strong>{event.title}</strong>
        </Space>
      )}
      <Dropdown menu={userDropdown} placement="bottomRight">
        <Button type="text">
          {isLoading && <Spin indicator={<CircleLoading />} />}
          {!isLoading && (
            <>
              {profile?.email} <DownOutlined />
            </>
          )}
        </Button>
      </Dropdown>
    </Header>
  )
}

export default PortalHeader
