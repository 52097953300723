import api, { useAPI } from './api'

// Get a organization record
export function useOrganization(organizationId) {
  return useAPI(organizationId && `/organizations/${organizationId}`)
}

// Update a organization record
export function updateOrganization(organizationId, organization) {
  return api.put(`/organizations/${organizationId}`, organization)
}

// Get list of organizations
export function useOrganizations() {
  return useAPI(`/organizations`)
}

// Get list of organization members
export function useOrganizationMembers(organizationId) {
  return useAPI(`/organizations/${organizationId}/members`)
}

// create a organization record
export function createOrganization(organization) {
  return api.post(`/organizations`, organization)
}

// delete a organization record
export function deleteOrganization(organizationId) {
  return api.delete(`/organizations/${organizationId}`)
}
