import api, { useAPI } from './api'
import { ROLE } from '../config/constants'
import { getPriorRoles } from '../utils/getPriorRoles'

// Create an event record
export function createEvent(event) {
  return api.post(`/events`, event)
}

// Get an event record
export function useEvent(eventId) {
  return useAPI(eventId && `/events/${eventId}`)
}

// Update an event record
export function updateEvent(eventId, event) {
  return api.put(eventId && `/events/${eventId}`, event)
}

// Remove an event record
export function removeEvent(eventId) {
  return api.delete(eventId && `/events/${eventId}`)
}

// Duplicate an event record
export function duplicateEvent(eventId) {
  return api.post(eventId && `/events/${eventId}/duplicate`)
}

// List out all events user related to
export function useEvents(profile) {
  let url
  if (getPriorRoles(ROLE.Admin).includes(profile?.role)) {
    url = '/events'
  } else {
    url = `/organizations/${profile?.organizationId}/events`
  }
  return useAPI(url)
}

// List out all assets under an event
export function useEventAssets(eventId) {
  return useAPI(`/events/${eventId}/assets`)
}

// List out all customers under an event
export function useEventCustomers(eventId) {
  return useAPI(`/events/${eventId}/customers`)
}

// List out all speakers under an event
export function useEventSpeakers(eventId) {
  return useAPI(`/events/${eventId}/speakers`)
}

// List out all speakers under an event
export function useEventBreakouts(eventId) {
  return useAPI(`/events/${eventId}/breakouts`)
}

// List out all pages under an event
export function useEventPages(eventId) {
  return useAPI(`/events/${eventId}/pages`)
}

// List out all customers under an event
export function useEventViewLogs(eventId, customerId) {
  return useAPI(eventId && customerId && `/events/${eventId}/viewlogs?customerId=${customerId}`)
}

// Get event deployment status
export function useEventDeployment(eventId) {
  return useAPI(`/events/${eventId}/deployment`)
}

// List all sessions of an event
export function useEventSessions(eventId) {
  return useAPI(`/events/${eventId}/sessions`)
}

// List all notifications of an event
export function useEventNotifications(eventId) {
  return useAPI(`/events/${eventId}/notifications`)
}

// List all notifications of an event
export function useEventVods(eventId) {
  return useAPI(`/events/${eventId}/vods`)
}

// List all groups of an event
export function useEventGroups(eventId) {
  return useAPI(`/events/${eventId}/groups`)
}

// List all groups of an event
export function useEventVendors(eventId) {
  return useAPI(`/events/${eventId}/vendors`)
}

// Clear viewlogs
export function clearViewLogs(eventId) {
  return api.delete(`/events/${eventId}/viewlogs`)
}

export function useEventSubscriptions(eventId) {
  return useAPI(`/events/${eventId}/subscriptions`)
}

export function useEventSubscription(eventId) {
  return useAPI(`/events/${eventId}/subscription`)
}

export function useEventEmailCampaigns(eventId) {
  return useAPI(`/events/${eventId}/campaigns`)
}

export function useEventEmailTemplates(eventId) {
  return useAPI(`/events/${eventId}/email-templates`)
}

export function getEventReport(eventId, params) {
  const query = new URLSearchParams(params).toString()
  return api.get(`/events/${eventId}/report?${query}`)
}

export function bypassPayment(eventId, payload) {
  return api.post(`/events/${eventId}/bypass-payment`, payload)
}

export function updateSubscription(eventId, payload) {
  return api.post(`/events/${eventId}/update-subscription`, payload)
}
